// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url("https://use.typekit.net/ldj4cqe.css");
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css");

// Variables
@import 'variables';

// Bootstrap

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins';

body{
    background: $background-color;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #fff;
}

::-webkit-scrollbar{
    width: 6px;
}

::-webkit-scrollbar-thumb{
    background: $accent;
    border-radius: 2rem;
}

// Fonts

h1, .h1{
    font-family: urw-din, sans-serif;
    font-size: 3rem;
    font-weight: 800;
    text-transform: uppercase;
}
h2,.h2{
    font-size: 2.625rem;
    font-family: urw-din, sans-serif;
    font-weight: 800;

}
h3,.h3{
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: urw-din, sans-serif;
    font-weight: 800;
}
p{
    line-height: 1.75rem;
}


// Fonts Breakpoints

@include media-breakpoint-only(xxl) {
    h1,.h1{
        font-size: clamp(3.8rem, 3.8vw, 6rem);
    }
}
@include media-breakpoint-only(xl) {
    h1,.h1{
        font-size: clamp(3.2rem, 3.5vw, 5rem);
    }
}
@include media-breakpoint-down(lg) {
    h1,.h1{
        font-size: 3rem;
    }
}
@include media-breakpoint-down(md) {
    h1,.h1{
        font-size: 2.2rem;
    }
}
@include media-breakpoint-down(sm) {
    h1,.h1{
        font-size: 1.8rem;
    }
}

// Global Styling

.container-fluid{
    max-width: 110rem;
    padding: 0 3rem;
    @include media-breakpoint-down(lg){
        padding: 0 1.5rem;
    }
    @include media-breakpoint-down(md){
        padding: 0 1rem;
    }
}

a.button{
    &.secondary{
            color: white;
            font-size: .75rem;
            letter-spacing: .06rem;
            text-transform: uppercase;
            font-weight: 600;
            text-decoration: unset;
    }
    @include media-breakpoint-down(sm) {
        font-size: .6rem;
    }
}

p.highlight{
    text-transform: uppercase;
    color: $accent;
    font-size: .75rem;
    letter-spacing: .2rem;
}
.sq-button{
    background: #8A2BE2;
    padding: .45rem 1rem;
    border-radius: .2rem;
    font-size: .7rem;
    font-weight: 600;
    cursor: pointer;
}